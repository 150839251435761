export default {
  'Bulk invite': 'Invitation groupée',
  'Download': 'Télécharger',
  'Download CSV template': 'Télécharger le modèle CSV',
  'Download error report': "Télécharger le rapport d'erreur",
  'Error': 'Erreur',
  'Member email': 'Adresse e-mail du membre',
  'Member role': 'Rôle du membre',
  'Upload CSV file': 'Téléverser le fichier CSV',
  'acceptsCSVfile': 'Accepte les fichiers CSV de moins de 5MB',
  'areYouSureToRemove': 'Êtes-vous sûr de vouloir supprimer',
  'doYouWantInviteData?': 'Vous souhaitez inviter { data } à rejoindre votre organisation ?',
  'doYouWantInviteMembers?':
    'Vous souhaitez inviter les membres de { count } à rejoindre votre organisation ?',
  'ensureFilledCorrectly':
    "Assurez-vous que le fichier est correctement rempli et qu'il ne contient pas d'erreurs.",
  'toInviteMemberCompleteForm': 'Pour inviter un membre, remplissez le formulaire ci-dessous.',
  'fillOutTemplate': 'Téléchargez et remplissez ce modèle pour inviter les membres.',
  'ignoreRows': 'Ignorer toutes les lignes contenant des erreurs',
  'inviteHasSent': "L'invitation a été envoyée à { method }",
  'invitesAreSent': 'Les invitations ont été envoyées aux utilisateurs.',
  'issuesWithContacts':
    "Nous avons identifié des contacts dans votre fichier { fileName } qui ne peuvent pas recevoir d'invitations. Téléchargez le fichier pour mettre à jour les contacts contenant des erreurs, ou procédez à l'envoi d'invitations uniquement aux contacts ne contenant pas d'erreurs.",
  'member': 'membre | membres',
  'members-bulk-invitation-error-report': 'membres-bulk-invitation-error-report',
  'membersRemoved': '@.capitalize:member a été supprimé de votre organisation.',
  'noneContactToInvite':
    "Aucun des contacts de votre fichier { fileName } ne peut recevoir l'invitation. Veuillez télécharger à nouveau le fichier pour mettre à jour les contacts présentant des erreurs.",
  'removeMember?': "Retirer @:{'member'} ?",
  'removingMemberOnlyRevokesAccess':
    "Le retrait d'un @:member de l'organisation ne fait que révoquer son accès à votre organisation et ne supprime pas son compte.",
  'selectRole': "Sélectionner le rôle de l'organisation des membres",
  'verifyRecipientsConsent':
    "Veuillez vérifier que tous les destinataires ont clairement donné leur consentement pour recevoir les e-mails d'inscription Keyo afin de garantir le respect des lois pertinentes en matière de protection des données et de la confidentialité.",
}
