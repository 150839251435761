<script setup lang="ts">
import ModalCard from '@/components/modals/components/ModalCard.vue'
import { IconBox } from '@keyo/ui'
import { computed, ref } from 'vue'
import type { Organization } from '@/modules/organization/types/model.ts'
import { useModal } from '@/composables'
import RequirementBase from '@/modules/organization/modals/OrganizationRequirements/RequirementBase.vue'
import { getOrganizationRequirements } from '@/modules/organization/utils'
import { usePersonalStore } from '@/modules/account'
import LegalBanner from '@/modules/organization/components/LegalBanner.vue'
import OrganizationDataHeader from '@/modules/organization/components/OrganizationDataHeader.vue'

const props = defineProps<{
  organization: Organization
}>()

const modal = useModal()
const personal = usePersonalStore()

const currentRequirementIndex = ref<number>(-1)
const currentRequirement = computed(() => requirements.value[currentRequirementIndex.value])

const requirements = computed(() => getOrganizationRequirements(props.organization))

const handleNextRequirement = () => {
  if (currentRequirementIndex.value === requirements.value.length - 1) {
    // refresh invites
    personal.getInvitations()
    personal.addJoinedBanner(props.organization.id, props.organization.name)
    modal.close()
    return
  }
  currentRequirementIndex.value++
}
</script>

<template>
  <ModalCard class="organization-requirements" :with-mobile-header-style="false">
    <OrganizationDataHeader :organization class="org-header" />

    <main>
      <RequirementBase v-if="!currentRequirement" @next="handleNextRequirement">
        <template #title>
          {{ $t('modules.organization.modals.organizationRequirements.title') }}
        </template>

        <template #description>
          {{
            $t(
              'modules.organization.modals.organizationRequirements.toJoinOrganizationCompleteRequirements',
            )
          }}
        </template>

        <ul class="org-requirement-list">
          <li v-if="!requirements.length" class="skeleton org-requirement-item" />
          <li
            v-for="requirement in requirements"
            :key="requirement.value"
            class="org-requirement-item"
          >
            <IconBox :name="requirement.icon" />
            <p>{{ requirement.name }}</p>
          </li>
        </ul>

        <LegalBanner
          class="org-legal"
          :terms-of-use="organization.terms_of_use"
          :privacy-policy="organization.privacy_policy"
        />
      </RequirementBase>

      <component
        :is="currentRequirement.component"
        v-else-if="currentRequirement?.component"
        :organization
        @next="handleNextRequirement"
      />
    </main>
  </ModalCard>
</template>

<style scoped lang="scss">
.organization-requirements {
  &.dialog-card.modal {
    display: flex;
    padding: 0;
  }

  :deep(.dialog-content) {
    margin-bottom: 0;
    grid-template-rows: max-content 1fr;
    flex: 1;
    gap: 0;
  }

  main {
    display: grid;
    background: #fff;
    padding: 2rem 2rem 1.5rem;
    width: 100%;

    .org-requirement-list {
      display: grid;
      gap: 0.5rem;
      flex: 1;

      .org-requirement-item {
        p {
          font: var(--text-label);
        }

        display: flex;
        align-items: center;
        gap: 1rem;
        padding-block: 1rem;
        position: relative;

        &:after {
          background-color: var(--color-grey-100);
          bottom: 0;
          content: '';
          height: 1px;
          position: absolute;
          width: 85%;
          inset-inline: 0;
          margin: auto;
          border-bottom-left-radius: 1rem;
          border-bottom-right-radius: 1rem;
        }
        &:last-child {
          &:after {
            display: none;
          }
        }

        p {
          font: var(--text-body-s);
        }
      }
    }
  }

  .org-legal {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  :deep(.btn-container) {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 1.5rem 1.5rem;

    .btn {
      flex: auto;
    }
  }
}

@media screen and (max-width: $mobile) {
  .organization-requirements {
    .org-header {
      padding-top: 7.5rem;
    }
  }
}
</style>
