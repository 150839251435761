import type { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import axios from 'axios'
import router from '../pages/router'
import { jwt } from './utils'
import { refreshToken } from './auth.ts'
import { isAuthenticated } from '@/composables/useAuthN.ts'

export const contentTypeV2 = { 'Content-Type': 'application/json; version=v2' }
const acceptVersion = 'v2'

const UAT = 'uat'
const URT = 'urt'

// TODO: maybe move to jwt utils
const setAccessJwt = (token: string) => localStorage.setItem(UAT, token)
export const getAccessJwt = () => localStorage.getItem(UAT)

const setRefreshJwt = (token: string) => localStorage.setItem(URT, token)
export const getRefreshJwt = () => localStorage.getItem(URT)

export const clearTokens = () => {
  localStorage.removeItem(UAT)
  localStorage.removeItem(URT)
}

const endpointsThatReturnJwts = [
  'auth/sign-in/',
  'auth/sign-in-mfa/',
  'auth/neat-sign-up/',
  'auth/activate/',
  'auth/password/restore/',
  'auth/password/set/',
  'auth/sign-up/',
  'auth/token/refresh/',
  'users/activate/',

  // v2
  'auth/neat-sign-up/v2/',
]

const endpointsWithJwtIfAuthenticated = ['auth/send-code/']

const endpointsPublic = [
  ...endpointsThatReturnJwts,
  ...endpointsWithJwtIfAuthenticated,
  'auth/password/send-restore-link/',
  'auth/password/send-restore-link-sms/',
  'auth/sign-up/validate-email/',
  'auth/sign-up/validate-hash/',
  'auth/neat-sign-up/validate-data/',
  'devices/identify-enrollment-user/',
  'auth/password/send-set-link/',
  'auth/pre-login-validation/',
  'users/resend-user-invitation/',
]

let isRefreshingJwts = false
let isRefreshingExpired = false
const postponeWhileRefreshingExpired = () => {
  return new Promise<void>(resolve => {
    if (!isRefreshingExpired) {
      return resolve()
    }
    const i = setInterval(() => {
      if (!isRefreshingExpired) {
        clearInterval(i)
        resolve()
      }
    }, 100)
  })
}

const checkIfEndpointRequiresJwt = (url: string) => {
  if (isAuthenticated.value && endpointsWithJwtIfAuthenticated.includes(url)) {
    return true
  }

  return !endpointsPublic.includes(url)
}

const requestInterceptor = async (config: InternalAxiosRequestConfig) => {
  if (config.url?.includes('auth/token/refresh/')) {
    config.data = { refresh: getRefreshJwt() }
  } else if (checkIfEndpointRequiresJwt(config.url ?? '')) {
    // all endpoints that require access token

    // in case when access token already expired we should wait for new one before proceed with original request.
    if (isRefreshingExpired) {
      // sync flow guard in case of multiple simultanious requests
      await postponeWhileRefreshingExpired()
    }

    try {
      if (jwt.isExpired(getRefreshJwt())) {
        throw new Error('Unauthenticated')
      }

      if (!isRefreshingJwts) {
        // prevent refreshing tokens when already in progress
        const accessJwt = getAccessJwt()
        if (jwt.isExpired(accessJwt)) {
          isRefreshingExpired = true // flag to postprone other requests while refreshing access token
          isRefreshingJwts = true
          await refreshToken() // postprone current request while refreshing access token
          isRefreshingExpired = false
          isRefreshingJwts = false
        } else if (jwt.getUntilExpiration(accessJwt) < 300) {
          // refresh when left less 5 min
          isRefreshingJwts = true
          refreshToken().finally(() => {
            isRefreshingJwts = false
          }) // token still valid so not need to postprone current requst
        }
      }
    } catch (e) {
      console.error(e)
      router.replace({ name: 'login' }) // Logout rest will handle route guard LINK src/pages/router.js#logout-router-guard

      // TODO: add handling axios Unauthenticated error and API jwt expired errors
      throw new axios.Cancel('Unauthenticated')
    }
  }
  if (checkIfEndpointRequiresJwt(config.url ?? '')) {
    config.headers.Authorization = `JWT ${getAccessJwt()}`
  }

  return config
}

const responseInterceptorSuccess = (resp: AxiosResponse) => {
  if (
    endpointsThatReturnJwts.includes(resp.config.url ?? '') &&
    resp.data.access &&
    resp.data.refresh
  ) {
    setAccessJwt(resp.data.access)
    setRefreshJwt(resp.data.refresh)
    delete resp.data.refresh
    delete resp.data.access
  }
  return resp
}

const responseInterceptorError = (e: AxiosError<any, any>) => {
  if (
    (e?.response?.data?.detail?.includes('User not found') ||
      e?.response?.data?.code === 'user_not_found') &&
    e?.response?.status === 401
  ) {
    router.replace({ name: 'login' }) // Logout rest will handle route guard LINK src/pages/router.js#logout-router-guard
  }
  return Promise.reject(e)
}

// Update headers for all api versions
export const updateApiHeaders = (headers: Record<string, string>) => {
  Object.assign(apiV3.defaults.headers, headers)
  Object.assign(apiV4.defaults.headers, headers)
}

export const apiV3 = axios.create({
  baseURL: import.meta.env.APP_BASE_API_URL,
})
apiV3.interceptors.request.use(requestInterceptor)
apiV3.interceptors.response.use(responseInterceptorSuccess, responseInterceptorError)

export const apiV4 = axios.create({
  baseURL: import.meta.env.APP_BASE_API_URL_V4,
})
apiV4.interceptors.request.use(requestInterceptor)
apiV4.interceptors.response.use(responseInterceptorSuccess, responseInterceptorError)

// in future can be dynamic e.g switching live and sandbox environments.
updateApiHeaders({
  Accept: `application/json; version=${acceptVersion}`,
})
