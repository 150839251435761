import { defineAsyncComponent } from 'vue'
import type {
  MappedRequirement,
  Organization,
  OrgRequirements,
} from '@/modules/organization/types/model.ts'
import { getOrganizationById, getOrganizationSettings } from '@/api/organization'

import walletApi from '@/modules/wallet/api'
import { i18n } from '@keyo/core/i18n'

export type DeviceOrganizationDataLocation = {
  country: {
    code: string
    name: string
  }
  city: string
  street: string
  street_address_line: string
  zip_code: number
  id: number
  state: string
}

export type DeviceOrganizationData = {
  id: number
  location: DeviceOrganizationDataLocation
  organization: {
    id: number
    name: string
    business_name: string
    logo: string
  }
}

const organizationCacheMap = new Map<number, Organization>()

// Currently it is specific to US but can be extended in the future
export const formatDeviceOrganizationAddress = (
  location: DeviceOrganizationDataLocation,
): string => {
  if (!location) return ''
  const { street_address_line, street, city, state, zip_code } = location
  return `${street_address_line ?? ''} ${street ?? ''} ${city ?? ''}, ${state ?? ''} ${
    zip_code ?? ''
  }`
}

const prepareRequirement = (requirement: OrgRequirements): MappedRequirement | null => {
  switch (requirement) {
    case 'payment_provider':
      return {
        component: defineAsyncComponent(
          () =>
            import(
              '@/modules/organization/modals/OrganizationRequirements/requirements/PaymentProvider.vue'
            ),
        ),
        name: i18n.global.t('modules.organization.requirements.connectMobileWallet'),
        value: 'payment_provider',
        icon: 'wallet',
      }
    default:
      return null
  }
}

export const getOrganizationRequirements = (organization: Organization): MappedRequirement[] => {
  const result: OrgRequirements[] = []

  if (organization.payment_provider && organization.payment_provider.is_required) {
    result.push('payment_provider')
  }

  if (!result) return []

  return result
    ?.map(requirement => {
      if (!requirement) return
      const preparedRequirement = prepareRequirement(requirement)
      return preparedRequirement ?? null
    })
    .filter(Boolean) as MappedRequirement[]
}

export const getOrganizationData = async (orgId: number): Promise<Organization> => {
  const organization = organizationCacheMap.get(orgId)

  if (!organization) {
    // TODO: Need to do this request since API is not sendig the whole date in the invitation object
    const [{ data: org }, { data: settings }] = await Promise.all([
      getOrganizationSettings(orgId),
      getOrganizationById(orgId),
    ])

    const result = {
      ...org,
      ...settings,
    }

    organizationCacheMap.set(orgId, result)
    return result
  }

  return organization
}

export const checkPendingRequirementsByOrg = async (
  organization: Organization,
): Promise<boolean> => {
  let requirementsMet = true

  const requirements = getOrganizationRequirements(organization)

  // Check payment provider requirement
  if (requirements.some(requirement => requirement.value === 'payment_provider')) {
    const { data } = await walletApi.fetchPaymentMethodsByOrgId(organization.id)
    requirementsMet = !!data.count
  }

  return Boolean(requirements.length && !requirementsMet)
}
