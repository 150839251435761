import { computed, onBeforeUnmount, ref, watch } from 'vue'
import type { RouteLocationRaw } from 'vue-router'
import { useRoute, useRouter } from 'vue-router'
import type {
  SignInMethod,
  SignUpBody,
  SignUpBaseBody,
  ActivateByInvitationBody,
} from '../api/auth.ts'
import {
  activateUserByInvitation,
  authSignUpValidate,
  authSignUp,
  authLogin,
  authRegister,
  authSignInMfa,
} from '../api/auth.ts'

import { usePersonalStore } from '../store'
import { isAuthRoute } from '@/pages/routes'
import type { Profile } from '@/modules/account/pinia'
import { censorEmail, censorString } from '@/utils/stringUtils'

// Dirty workaround to handle code resend, sending creds to get new code
// API should provide proper functionality instead
const storedCredentials = ref<{
  emailOrPhone?: string
  email?: string
  phone?: string
  password?: string
  code?: string
  client_id?: string
  method?: SignInMethod
  first_name?: string
  last_name?: string
  captcha_token?: string
}>({})

// API should provide in response censored email or phone instead
const censoredAuthData = computed<{
  emailOrPhone?: string
  email?: string
  phone?: string
}>(() => {
  if (storedCredentials.value?.emailOrPhone) {
    const emailOrPhone = storedCredentials.value?.emailOrPhone
    return {
      emailOrPhone: emailOrPhone.includes('@')
        ? censorEmail(emailOrPhone)
        : censorString(emailOrPhone),
    }
  } else if (storedCredentials.value.email || storedCredentials.value.phone) {
    return {
      ...(storedCredentials.value.email
        ? { email: censorEmail(storedCredentials.value.email) }
        : {}),
      ...(storedCredentials.value.phone
        ? { phone: censorString(storedCredentials.value.phone) }
        : {}),
    }
  }

  return {}
})

export const isAuthenticated = ref(false)

export default function () {
  const router = useRouter()
  const route = useRoute()
  const personal = usePersonalStore()
  const persistentCredsRoutes = [
    'login.code',
    'activate.verify',
    'activate.verify.edit',
    'activate.verify.edit.method',
    'activate.code',
    'register', // to persist registration form when going back from verify email/phone page
  ]

  async function signOut() {
    isAuthenticated.value = false
    watchStopHandle()
    return router.replace({ name: 'login' })
  }

  const watchStopHandle = watch(route, to => {
    if (isAuthRoute(route)) {
      isAuthenticated.value = false
    }

    // Cleanup storedCredentials from memory once user changes page
    if (!persistentCredsRoutes.includes(to.name as string)) {
      clearStoredCredentials()
    }
  })
  onBeforeUnmount(() => watchStopHandle())

  async function handleUserLogin(user: Profile) {
    if (!user) return
    personal.profileSet(user)

    isAuthenticated.value = true

    const nextRoute = personal.isProfileComplete
      ? route.query.redirect || { name: 'personal', query: route.query }
      : { name: 'activate.full-name', query: route.query }

    return router.replace(nextRoute as string | RouteLocationRaw)
  }

  /**
   * Sign in with email/phone and password
   * @param {object} credentials
   * @param invitationId
   * @param {string} credentials.email
   * @param {string} credentials.phone
   * @param {string} credentials.password
   */
  async function signIn(credentials = storedCredentials.value, invitationId?: string) {
    if (!credentials) return

    const methodType = credentials.method === 'email' ? 'email' : 'phone'

    const payload = {
      method: credentials.method as SignInMethod,
      password: credentials.password as string,
      [methodType]: credentials[methodType] as string,
      captcha_token: credentials.captcha_token as string,
    }

    const response = await authLogin(payload)

    if (!response) {
      throw new Error()
    }

    if (response.data.is_mfa) {
      storedCredentials.value = { ...response.data, ...credentials }

      const query: Record<string, string> = {
        ...route.query,
        b: response.data.client_id,
      }

      if (invitationId) {
        query.invitationId = invitationId
      }

      return router.replace({
        name: 'login.code',
        query,
      })
    }

    if (invitationId) {
      await personal.preloadInvitationRequirements({
        invitationId: Number(invitationId),
      })
    }

    return handleUserLogin(response.data.user)
  }

  async function signUpValidate(body: SignUpBaseBody) {
    const response = await authSignUpValidate(body)
    storedCredentials.value = {
      ...storedCredentials.value,
      ...body,
    }

    return response
  }

  async function signUpByInvitation(payload: ActivateByInvitationBody) {
    const response = await activateUserByInvitation(payload)

    if (!response) {
      throw new Error()
    }

    await personal.preloadInvitationRequirements({
      organizationId: payload.organization,
    })

    return handleUserLogin(response.data.user)
  }

  async function signUp(code: string) {
    const { email, phone, first_name, last_name, client_id, password } =
      storedCredentials.value as SignUpBody

    const response = await authSignUp({
      email,
      phone,
      first_name,
      last_name,
      password,
      code,
      client_id,
    } satisfies SignUpBody)

    if (!response) {
      throw new Error()
    }

    return handleUserLogin(response.data.user)
  }

  async function register(email: string) {
    return authRegister(email)
  }

  async function confirm2fa(code: string, invitationId?: string) {
    const resp = await authSignInMfa({
      method: 'email',
      client_id: route.query.b as string,
      code,
    })

    if (invitationId) {
      await personal.preloadInvitationRequirements({
        invitationId: Number(invitationId),
      })
    }

    return handleUserLogin(resp.data.user)
  }

  const updateStoredCredentials = (newCredentials: typeof storedCredentials.value) => {
    storedCredentials.value = {
      ...storedCredentials.value,
      ...newCredentials,
    }
  }

  const getStoredCredentials = () => storedCredentials.value

  const clearStoredCredentials = () => {
    storedCredentials.value = {}
  }

  return {
    signOut,

    register,
    confirm2fa,

    signIn,

    signUp,
    signUpValidate,

    signUpByInvitation,
    getStoredCredentials,
    clearStoredCredentials,
    updateStoredCredentials,

    handleUserLogin,

    censoredAuthData,
    isAuthenticated,
    storedCredentials,
  }
}
