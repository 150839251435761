<script setup lang="ts">
import { DialogBtn } from '@keyo/ui'
import { useI18n } from 'vue-i18n'

interface Props {
  tag?: keyof HTMLElementTagNameMap
  loading?: boolean
}

withDefaults(defineProps<Props>(), {
  tag: 'div',
})

defineEmits(['next'])

const { t } = useI18n()
</script>
<template>
  <component :is="tag" class="requirement-base">
    <div class="content">
      <h2 v-if="$slots.title" class="title">
        <slot name="title" />
      </h2>
      <p v-if="$slots.description" class="description">
        <slot name="description" />
      </p>

      <slot />
    </div>

    <div class="footer">
      <div class="divider" />
      <DialogBtn :type="tag === 'form' ? 'submit' : 'button'" :loading @click="$emit('next')">
        {{ t('common.next') }}
      </DialogBtn>
    </div>
  </component>
</template>

<style lang="scss" scoped>
.requirement-base {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .content {
    display: grid;

    :deep(.dropdown) {
      --dropdown-menu-height: 8rem;
    }

    .title,
    .description {
      margin-bottom: 1rem;
    }

    .title {
      font: var(--text-heading-s);
    }

    .description {
      font: var(--text-body-m);
    }
  }

  .footer {
    display: grid;
    gap: 2rem;

    .divider {
      height: 1px;
      background: var(--color-grey-200);
      margin-top: 2.5rem;
    }
  }
}

@media screen and (max-width: $mobile) {
  .requirement-base {
    .content {
      :deep(.dropdown) {
        --dropdown-menu-height: 15rem;
      }
    }
    .divider {
      display: none;
    }
  }
}
</style>
