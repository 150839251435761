<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps<{
  termsOfUse: string
  privacyPolicy: string
}>()

const showLegalBanner = computed(() => props.termsOfUse || props.privacyPolicy)
</script>

<template>
  <div v-if="showLegalBanner" class="legal-banner">
    <I18nT keypath="common.orgLegalMessage">
      <template #legal>
        <a v-if="termsOfUse" :href="termsOfUse" target="_blank">
          {{ $t('common.termsOfUse') }}
        </a>
        <template v-if="termsOfUse && privacyPolicy">
          {{ ` ${$t('common.and')} ` }}
        </template>
        <a v-if="privacyPolicy" :href="privacyPolicy" target="_blank">
          {{ $t('common.privacyPolicy') }}
        </a>
      </template>
    </I18nT>
  </div>
</template>

<style scoped lang="scss">
.org-legal {
  border-radius: 1rem;
  background-color: var(--color-grey-100);
  padding: 0.75rem;
  text-align: center;
  font: var(--text-body-xs);

  a {
    text-decoration: underline;
  }
}
</style>
