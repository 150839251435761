import { defineStore } from 'pinia'
import toast from '@/libs/toast'
import _get from 'lodash/get'

import { usePersonalStore } from '@/modules/account'
import {
  createOrganization,
  getOrganizationById,
  getOwnOrganizationsList,
  organizationLeave,
  updateOrganizationById,
  updateOrganizationLogo,
} from '@/api/organization'

export const MEMBERSHIP_STATUS = {
  INVITATION_SENT: 0,
  ACTIVE: 1,
  PAUSED: 2,
  DEACTIVATED: 3,
}

function checkCompleteness(org) {
  const profileCompleteFields = [
    'name',
    'shipping_address.country',
    'shipping_address.state',
    'shipping_address.city',
    'shipping_address.zip_code',
    'shipping_address.street',
    'business_contact_first_name',
    'business_contact_last_name',
    'business_email',
  ]
  for (let i = 0; i < profileCompleteFields.length; i++) {
    if (!_get(org, profileCompleteFields[i])) return false
  }
  return true
}

const prepareOrg = ({ organization_roles: roles, membership, ...org }) => {
  org.roles = roles
  org.isComplete = checkCompleteness(org)
  org.roles = roles
  // membership not always included in response
  if (membership) {
    org.member = {
      role: membership.organization_role.name,
      status: membership.status,
      id: membership.id,
    }
  }
  return org
}

export const useOrganizationsStore = defineStore('organizations', {
  state: () => ({
    items: new Map(),
    order: [], // maybe sidebar order
  }),
  actions: {
    async create(body) {
      await createOrganization(body)
      await this.getList() // TODO: fix/workaround: membership missing in response

      // TODO: fix/workaround: personal organization widget currently checked via personal profile.
      const personal = usePersonalStore()
      await personal.profileFetch()
    },

    async getList() {
      const resp = await getOwnOrganizationsList()
      this.$patch(s => {
        resp.data.results.forEach(o => s.items.set(`${o.id}`, prepareOrg(o)))
      })
    },

    async fetchById(id, options = { redirect: true }) {
      try {
        const resp = await getOrganizationById(id)
        const oAssignTo = this.items.get(id)
        oAssignTo
          ? Object.assign(oAssignTo, prepareOrg(resp.data))
          : this.items.set(`${resp.data.id}`, prepareOrg(resp.data))
      } catch (e) {
        toast.show("Organization not found or you don't have permission", 'error')
        options?.redirect && this.router.push('/personal')
        console.error(e)
        throw e
      }
    },

    async update(id, body) {
      const resp = await updateOrganizationById(id, body)
      const oAssignTo = this.items.get(id)
      oAssignTo
        ? Object.assign(oAssignTo, prepareOrg(resp.data))
        : this.items.set(`${resp.data.id}`, prepareOrg(resp.data))
    },

    async updateLogo(id, logo) {
      const resp = await updateOrganizationLogo(id, logo)
      const oAssignTo = this.items.get(id)
      oAssignTo
        ? Object.assign(oAssignTo, prepareOrg(resp.data))
        : this.items.set(`${resp.data.id}`, prepareOrg(resp.data))
    },

    async leave(orgId) {
      try {
        await organizationLeave(orgId)
        this.items.delete(`${orgId}`)
      } catch (error) {
        console.error(error)
      }
    },
  },
  getters: {
    /** organizations where user has some administrative access */
    spaces() {
      return Array.from(this.items.values()).filter(
        o => o.member.role !== 'User' && o.member.status.value === MEMBERSHIP_STATUS.ACTIVE,
      )
    },
    // atm for network widget
    networks() {
      return Array.from(this.items.values()).filter(
        o => o.member.role === 'User' && o.member.status.value === MEMBERSHIP_STATUS.ACTIVE,
      )
    },
    withPayments() {
      return Array.from(this.items.values()).filter(o => !!o.payment_provider_ids.length)
    },
  },
})
